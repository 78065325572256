const bpStory = {
    updateURL(URL) {
        window.location.hash = URL;
    },
    checkURL(URL) {
        let pattern = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
        return URL.match(pattern) ? true : false;
    },
    forEach({array, callback, done}) {
        let result = null;
        return new Promise((resolve) => {
            array.forEach(async (v, i) => {
                let variables = await callback(v, i);
                // array finish
                if ((array.length-1) == i) {
                    if (done) result = done(variables);
                    resolve(result);
                }
            });
        });
    },
    isMobile() {
        if (window.innerWidth < 450) {
            return window.innerWidth;
        } 
        return false;
    },
    fileToBase64(file) {
        return new Promise((resolve, reject) => {
            if (file) { 
                let reader = new FileReader(); 
                reader.onload = function(e) { 
                    resolve(e.target.result);
                }; 
                reader.readAsDataURL(file); 
            } else {
                reject(false);
            }
        });
    },
    randomKey() {
        return Math.floor(Math.random() * 1000000);
    },
    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    },
    getRealVal(el, property) {
        return parseFloat(String(getComputedStyle(el)[property]).replace('px', ''));
    },
    isElement(el){
        return (
            typeof HTMLElement === "object" ? el instanceof HTMLElement : //DOM2
            el && typeof el === "object" && el !== null && el.nodeType === 1 && typeof el.nodeName==="string"
        );
    }
}

export default bpStory;