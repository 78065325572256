<template>
    <div class="story-info">
        <div v-if="this.$root.options && this.$root.options.storyBoxRedirectURL">
            <a class="user-profile" :href="createStoryBoxRedirectURL(story.storyBox)">
                <img :src="story.storyBoxImage" :alt="story.storyBox">
                <span>{{story.storyBox}}</span>
            </a>
        </div>
        <div v-else>
            <div class="user-profile">
                <img :src="story.storyBoxImage" :alt="story.storyBox">
                <span>{{story.storyBox}}</span>
            </div>
        </div>
        <div class="publish-time"></div>
    </div>
</template>

<script>
export default {
    name: 'storyInfo',
    props: ['story'],
    methods: {
        createStoryBoxRedirectURL(storyBox) {
            let storyBoxRedirectURL = this.$root.options.storyBoxRedirectURL
            storyBoxRedirectURL += storyBoxRedirectURL.endsWith("/") ? "" : "/";
            return storyBoxRedirectURL + storyBox;
        }
    }
}
</script>

<style lang='scss' scoped>
    .story-info {
        position: absolute;
        z-index: 2;
        top: 35px;
        left: 15px;
        font-family: 'Segoe UI Regular';
        display: flex;
        .user-profile {
            display: flex;
            align-items: center;
            text-decoration: none;
            img {
                width: 30px;
                height: 30px;
                border-radius: 50%;
                margin-right: 5px;
            }
            span {
                font-weight: 550;
                color: #fff;
                font-size: 16px;
                margin-bottom: 4px;
            }
        }
        .publish-time {
            display: flex;
            margin-left: 5px;
            align-items: center;
            color: #fff;
            opacity: .8;
            font-size: 13px;
            margin-bottom: 4px;
        }
    }
    @media screen and (max-width: 450px) {
        .story-info {
            left: 45px;
        }
    }
</style>