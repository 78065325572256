<template>
    <ImageEditor ref="imageEditor" :include-ui="useDefaultUI" :options="options"></ImageEditor>
</template>

<script>
import 'tui-color-picker/dist/tui-color-picker.css';
import 'tui-image-editor/dist/tui-image-editor.css';
import ImageEditor from '@toast-ui/vue-image-editor';
export default {
    name: 'imageEditor',
    components: {
        ImageEditor
    },
    data: () => ({
        useDefaultUI: true,
        options: {
            cssMaxHeight: 350,
        },
    }),
    async mounted() {
        // set imageEditor
        this.$store.dispatch('setImageEditor', this.$refs.imageEditor);

        // remove image editors spaces
        document.querySelector('.tui-image-editor-header-logo').style.display = 'none';
        document.querySelector('.tui-image-editor-header-buttons').innerHTML = '';
    }
}
</script>

<style lang='scss'>
    .tui-image-editor-container {
        width: 100vw!important;
        height: 100vh!important;
        font-family: 'Segoe UI Regular';
        .tui-image-editor-help-menu.top{
            .tie-btn-reset, .tie-btn-history {
                display: none;
            }
            width: 400px;
            top: 10px;
        }
    }
    @media screen and (max-width: 1010px) {
        .tui-image-editor-container {
            .tui-image-editor-help-menu.top{
                transform: none;
                left: 50px;
                top: 10px;
            }
        }
    }
    @media screen and (max-width: 900px) {
        .tui-image-editor-container {
            .tui-image-editor-menu-filter {
                .tui-image-editor-submenu-item {
                    overflow-y: auto;
                    white-space: nowrap;
                    padding: 0 25px;
                    width: 100vw;
                    li:not(.tui-image-editor-partition){
                        width: 190px;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 750px) {
        .tui-image-editor-container {
            .tui-image-editor-help-menu.top{
                width: 240px;
                top: 150px;
                left: 30px;
                background-color: #151515;
                transform: translateX(-50%) rotate(90deg);
                .tui-image-editor-item {
                    width: 16px!important;
                    height: 24px!important;
                    padding: 0;
                    &[tooltip-content] {
                        transform: translateY(-50%);
                    }
                    &:not([tooltip-content]) {
                        height: 40px!important;
                        .tui-image-editor-icpartition {
                            height: 40px!important;
                        }
                    }
                }
                .svg_ic-menu {
                    transform: rotate(-90deg);
                    width: 16px;
                    height: 16px;
                }
            }
            .tui-image-editor-menu-icon , .tui-image-editor-menu-text {
                .tui-image-editor-submenu-item {
                    overflow-y: auto;
                    white-space: nowrap;
                    width: 100vw;
                }
            }
        }
    }
    @media screen and (max-width: 450px) {
        .tui-image-editor-container {
            .tui-image-editor-menu {
                .tui-image-editor-item {
                    width: 24px!important;
                    height: 24px!important;
                    padding: 0;
                    padding-top: 3px;
                }
            }
            .svg_ic-menu {
                width: 18px!important;
                height: 18px!important;
            }
            .svg_ic-submenu {
                width: 24px!important;
                height: 24px!important;
            }
        }
    }
</style>