<template>
    <div 
        class="story-box" 
        :data-order="index"
        :data-story-box="story.storyBox" 
        v-if="story.storyItems.length != 0"
    >
        <storyBoxOpener :story="story" />
        <div class="story-box-header">
            <pointers :story="story" />
            <storyInfo :story="story" />
            <headerMenu :story="story" />
        </div>
        <div class="story-item-wrapper">
            <div 
                class="story-item"
                :class="getStatusByCookies(story.storyBox, storyItem, itemIndex)"
                v-for="(storyItem, itemIndex) in story.storyItems"
                :key="itemIndex"
                :data-order="itemIndex"
                :data-story-id="storyItem.storyId"
                :data-publish-time="storyItem.publishTime" 
                :data-transition-time="storyItem.transitionTime"
            >
                <div v-if="imageTypes.includes(parsMediaType(storyItem.mediaURL))" class="story-item-content">
                    <img :src="storyItem.mediaURL" :alt="storyItem.mediaURL">
                </div>
                <div v-else class="story-item-content">
                    <video :src="storyItem.mediaURL" muted></video>
                </div>
                <div class="story-item-footer" v-if="storyItem.externalURL">
                    <a 
                        :href="storyItem.externalURL" 
                        :alt="$store.getters.multiLang.seeMore" 
                        @click.prevent="openLink(storyItem.externalURL)"
                    >
                        {{$store.getters.multiLang.seeMore}}  >
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import pointers from "@/components/viewer/pointers.vue";
import storyInfo from "@/components/viewer/storyInfo.vue";
import headerMenu from "@/components/viewer/headerMenu.vue";
import storyBoxOpener from "@/components/viewer/storyBoxOpener.vue";
export default {
    name: 'storyBox',
    components: {
        storyBoxOpener,
        headerMenu,
        storyInfo,
        pointers,
    },
    data: () => ({
        imageTypes: [
            'jpg',
            'jpeg',
            'png',
            'gif',
            'ico',
            'webp' 
        ],
        activeStory: false,
        seenCookie: null
    }),
    props: ['index', 'story'],
    mounted() {
        let bpStory = document.getElementById(this.$root.bpStoryId);

        if (!this.activeStory) {
            let activeStoryBox = bpStory.querySelector('.story-box[data-story-box="'+this.story.storyBox+'"]');
            if (activeStoryBox) activeStoryBox.querySelector(".story-item:first-child").classList.add('active');
        }
    },
    async beforeCreate() {
        let seenCookie = await this.$store.dispatch('cookies/get', 'seen');
        this.seenCookie = seenCookie;
    },
    methods: {
        parsMediaType(mediaURL) {
            mediaURL = mediaURL.split("."); 
            return mediaURL[mediaURL.length - 1];
        },
        openLink(URL) {
            this.$store.dispatch('setControllersPause', true);
            this.$store.dispatch('autoTransition/disabled', true);
            this.$store.dispatch('autoTransition/paused', true);
            window.open(URL, '_blank');
        },
        getStatusByCookies(storyBox, storyItem, index) {
            let className = '';

            if (this.activeStory && this.activeStory == storyItem.storyId) return 'active';

            let seenCookie = this.seenCookie;
            if (seenCookie && seenCookie[storyBox]) {
                let storyCookie = seenCookie[storyBox];
                if (storyCookie.activeStoryId == storyItem.storyId) {
                    className += 'active';
                } else if (!this.activeStory && storyCookie.activeStoryOrder == index) {
                    className += 'active';
                }
            } else if (index == 0) {
                className += 'active';
            }
            
            if (className.indexOf('active') !== -1) {
                this.activeStory = storyItem.storyId;
            }

            return className;
        }
    }
}
</script>

<style lang='scss' scoped>
    .story-box {
        float: left;
        width: 140px;
        height: 38.113%;
        margin-right: 40px;
        position: relative;
        border-radius: 12px;
        background: #000;
        box-shadow: 0px 0px 15px 2px rgba(0,0,0,0.2);
        &:not(.loading) {
            transition: 350ms linear;
        }
        .story-box-header {
            display: none;
        }
        &::before, &::after {
            content: '';
            display: block;
            width: 100%;
            height: 100px;
            position: absolute;
            left: 0;
            background: rgb(2,0,36);
            z-index: 1;
        }
        &::before {
            top: 0;
            border-top-left-radius: 10.5px;
            border-top-right-radius: 10.5px;
            background: linear-gradient(180deg, rgba(2,0,36,0.6418942577030813) 0%, rgba(0,212,255,0) 100%);
        }
        &::after {
            bottom: 0;
            border-bottom-left-radius: 10.5px;
            border-bottom-right-radius: 10.5px;
            background: linear-gradient(0deg, rgba(2,0,36,0.6418942577030813) 0%, rgba(0,212,255,0) 100%);
        }
        .story-item-wrapper {
            width: 100%;
            height: 100%;
            position: relative;
        }
        .story-item {
            width: 100%;
            height: 100%;
            position: relative;
            display: none;
            align-items: center;
            justify-content: center;
            .story-item-content {
                display: flex;
                overflow: hidden;
                align-items: center;
                justify-content: center;
                border-radius: 12px;
                width: 100%;
                height: 100%;
                img, video {
                    max-height: 101%;
                    max-width: 101%;
                    width: auto;
                }
            }
            .story-item-footer {
                position: absolute;
                left: 0;
                bottom: 20px;
                width: 100%;
                height: 30px;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                z-index: 3;
                display: none;
                a {
                    font-family: 'Segoe UI Regular';
                    text-decoration: none;
                    color: #fff;
                    font-size: 16px;
                }
            }
            &.active {
                display: flex;
            }
        }
        .story-item, .story-box-opener-container, .story-item-wrapper {
            border-radius: 12px;
        }
        &.active {
            .story-box-opener-container {
                display: none;
            }
            width: 344px;
            height: 94%;
            &:not(.loading) {
                transition: 350ms linear;
            }
            .story-box-header {
                display: block;
            }
            .story-item {
                &.active {
                    .story-item-footer {
                        display: block;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 450px) {
        .story-box.active {
            height: 100%;
            border-radius: 0px;
            .story-item, 
            .story-box-opener-container,
            &::before, &::after, 
            .story-item-wrapper,
            .story-item-content {
                border-radius: 0!important;
            }
        }
    }
</style>