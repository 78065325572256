<template>
    <div class="video-editor closed">
        <video muted controls></video>
    </div>
</template>

<script>
export default {
    name: 'videoEditor'
}
</script>

<style lang="scss" scoped>
    .video-editor {
        background: #141313;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        video {
            width: auto;
            max-height: 90%;
        }
        &.closed {
            display: none;
        }
    }
    @media screen and (max-width: 750px) {
        .video-editor {
            video {
                margin-top: 50px;
                max-height: 80%;
            }
        }
    }
</style>