<template>
    <div class="animation-container closed">
        <div class="loading-animation">
            <div class="animation-wrapper">
                <div class="animation-dot-wrapper">
                    <div class="animation-dot"></div>
                    <div class="animation-dot"></div>
                    <div class="animation-dot"></div>
                    <div class="animation-dot"></div>
                    <div class="animation-dot"></div>
                    <div class="animation-dot"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'loadingAnimation'
}
</script>

<style lang='scss' scoped>
.animation-container{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #1a1a1a;
    z-index: 10;
    &.closed {
        display: none;
    }
    .loading-animation{
        width: 100%;
        height: 100%;
        .animation-wrapper{
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            .animation-dot-wrapper {
                width: 40px;
                height: 40px;
                position: relative;
                animation: animation-chase 2.5s infinite linear both;
            }
            .animation-dot {
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0; 
                animation: animation-chase-dot 2.0s infinite ease-in-out both; 
                &::before {
                    content: '';
                    display: block;
                    width: 25%;
                    height: 25%;
                    border-radius: 100%;
                    animation: animation-chase-dot-before 2.0s infinite ease-in-out both;
                    background: #fff;
                }
            }
            .animation-dot:nth-child(1) { animation-delay: -1.1s; }
            .animation-dot:nth-child(2) { animation-delay: -1.0s; }
            .animation-dot:nth-child(3) { animation-delay: -0.9s; }
            .animation-dot:nth-child(4) { animation-delay: -0.8s; }
            .animation-dot:nth-child(5) { animation-delay: -0.7s; }
            .animation-dot:nth-child(6) { animation-delay: -0.6s; }
            .animation-dot:nth-child(1):before { animation-delay: -1.1s; }
            .animation-dot:nth-child(2):before { animation-delay: -1.0s; }
            .animation-dot:nth-child(3):before { animation-delay: -0.9s; }
            .animation-dot:nth-child(4):before { animation-delay: -0.8s; }
            .animation-dot:nth-child(5):before { animation-delay: -0.7s; }
            .animation-dot:nth-child(6):before { animation-delay: -0.6s; }
            @keyframes animation-chase {
                100% {
                    transform: rotate(360deg); 
                } 
            }
            @keyframes animation-chase-dot {
                80%, 100% {
                    transform: rotate(360deg);
                } 
            }
            @keyframes animation-chase-dot-before {
                50% {
                    transform: scale(0.4); 
                } 100%, 0% {
                    transform: scale(1.0); 
                } 
            }
        }
    }
}
</style>