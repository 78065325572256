<template>
    <div class="bpstory-creator">
        <div v-if="$store.getters['stories/getList'][0].currentUser">
            <div class="creator-buttons">
                <div class="select-new-media">{{$store.getters.multiLang.selectNewMedia}}</div>
                <div class="publish-btn">{{$store.getters.multiLang.publish}}</div>
            </div>

            <div class="close-btn" @click="closeCreator"></div>

            <input type="file" id="media-uploader" style="display:none" hidden :accept="supportedMediaTypes()">
            
            <!-- editors -->
            <imageEditor />
            <videoEditor />
        </div>
    </div>
</template>

<script>
import imageEditor from './imageEditor.vue';
import videoEditor from './videoEditor.vue';
export default {
    name: 'storyCreator',
    components: {
        videoEditor,
        imageEditor
    },
    data: () => ({
        mediaType: null
    }),
    methods: {  
        async closeCreator() {
            let { storyCreator, mediaUploader } = await this.$store.dispatch('getElements');
            storyCreator.classList.remove('opened');
            mediaUploader.value = '';
            document.querySelector('body').style.overflow = 'visible';
        },
        supportedMediaTypes() {
            let supportedMediaTypes = this.$store.getters.supportedMediaTypes;
            supportedMediaTypes = '.' + supportedMediaTypes.join(',.');
            return supportedMediaTypes;
        },
        checkURL() {
            let URLAddress = prompt(this.$store.getters.multiLang.enterURLAddress);
            if (URLAddress == null) return false;
            if (!bpStory.checkURL(URLAddress)) {
                alert(this.$store.getters.multiLang.invalidURL);
                this.checkURL();
            } else {
                return URLAddress;
            }
        }
    },
    async mounted() {

        let { mediaUploader, storyCreator } = await this.$store.dispatch('getElements');

        if (!mediaUploader) return;

        mediaUploader.addEventListener('change', async () => {
            let { mediaUploader, storyCreator, videoEditor } = await this.$store.dispatch('getElements');
            if (!mediaUploader.value) return;
            let parsedPath = String(mediaUploader.value).split('.');
            let mediaType = parsedPath[parsedPath.length-1].toLowerCase();
            this.mediaType = mediaType;
            let mediaFile = mediaUploader.files[0];
            let mediaName = mediaFile.name.toLowerCase();
            mediaName = mediaName.replace('.'+mediaType, '');
            let mediaFileSize = mediaFile.size;
            if (!this.$store.getters.supportedMediaTypes.includes(mediaType)) {
                alert(this.$store.getters.multiLang.unsupportedMedia);
                mediaUploader.value = '';
                return;
            } else if (mediaFileSize > (this.$store.getters.maxFileSize * 1024 * 1024)) {
                alert(this.$store.getters.multiLang.maxFileSizeWarning);
                mediaUploader.value = '';
            } else {
                let imageEditor = this.$store.getters.imageEditor.getRootElement();
                if (this.$store.getters.imageTypes.includes(mediaType)) {
                    this.$store.getters.imageEditor.invoke(
                        'loadImageFromFile', 
                        mediaFile, 
                        mediaName
                    );
                    this.$store.getters.imageEditor.invoke('ui.activeMenuEvent');
                } else {
                    // burası video editörü için
                    let videoPlayer = videoEditor.querySelector('video');
                    videoPlayer.src = URL.createObjectURL(mediaFile);
                    videoEditor.classList.remove('closed');
                    imageEditor.style.display = 'none';
                }
                document.querySelector('body').style.overflow = 'hidden';
                storyCreator.classList.add('opened');
            }
        });

        storyCreator.querySelector('.select-new-media').addEventListener('click', async () => {
            let { mediaUploader } = await this.$store.dispatch('getElements');
            mediaUploader.click();
        });

        storyCreator.querySelector('.publish-btn').addEventListener('click', async () => {
            
            let { mediaUploader, waiting } = await this.$store.dispatch('getElements');

            let formData = new FormData();

            // get media to base64 format
            let media;
            if (this.$store.getters.imageTypes.includes(this.mediaType)) {
                media = document.querySelector('.lower-canvas').toDataURL();
            } else {
                media = await bpStory.fileToBase64(mediaUploader.files[0]);
            }
            formData.append('media', media) ;

            // get user data
            let wantAddURL = confirm(this.$store.getters.multiLang.wantAddURL);
            if (wantAddURL) {
                let URLAddress = this.checkURL();
                if (URLAddress) formData.append('externalURL', URLAddress);
            }

            waiting.classList.add('opened');

            this.axios.post('?action=createNewStory', formData)
            .then(({data}) => {
                if (data.message) alert(data.message);
                if (data.success) {
                    this.$root.EventBus.$emit('refresh');
                    this.closeCreator();
                }
            })
            .catch((err) => {
                if (err.response.data.message) alert(err.response.data.message);
            })
            .then(() => {
                waiting.classList.remove('opened');
            });

        });

        if (document.querySelector('body > .bpstory-creator')) {
            document.querySelector('body > .bpstory-creator').remove();
        }
        document.body.appendChild(this.$el);
    }
}
</script>

<style lang='scss' scoped>
    #media-uploader {
        display: none!important
    }
    .bpstory-creator {
        position: fixed;
        background: #1E1E1E;
        width: 100%;
        height: 100%;
        bottom: -110%;
        left: 0;
        transition: all 200ms;
        z-index: 999999999999;
        .creator-buttons {
            position: absolute;
            top: 10px;
            right: 10px;
            z-index: 10;
            .publish-btn, .select-new-media {
                font-family: "Noto Sans", sans-serif; 
                font-size: 12px; 
                width: auto; 
                padding: 0px 15px;
                z-index: 10;
                height: 40px;
                line-height: 40px;
                font-weight: bold;
                cursor: pointer;
                vertical-align: middle;
                letter-spacing: 0.3px;
                text-align: center;
                border-radius: 20px;
                display: inline-block;
            }
            .select-new-media {
                background-color: rgb(255, 255, 255);
                border: 1px solid rgb(221, 221, 221); 
                color: rgb(34, 34, 34);
                margin-right: 10px; 
            }
            .publish-btn {
                background-color: rgb(253, 186, 59); 
                border: 1px solid rgb(253, 186, 59); 
                color: rgb(255, 255, 255); 
            }
        }
        &.opened {
            transition: all 200ms;
            bottom: 0;
        }
        .close-btn {
            width:25px;
            height: 24px;
            top: 15px;
            left: 15px;
            position: absolute;
            cursor: pointer;
            z-index: 10;
            &::before, &::after {
                content: '';
                display: block;
                width: 2.5px;
                height: 24px;
                border-radius: 5px;
                background: #fff;
                position: absolute;
                left: calc(50% - 2.5px / 2);
                top: 0px;
            }
            &::before {
                transform: rotate(45deg);
            }
            &::after {
                transform: rotate(-45deg);
            }
        }
    }
    
    @media screen and (max-width: 450px) {
        .creator .creator-buttons {
            .publish-btn, .select-new-media {
                height: 28px;
                line-height: 28px;
            }
        }
    }
</style>