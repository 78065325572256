<template>
    <li 
        class="list-item"
        :class="seen ? 'seen' : ''" 
        @click.prevent="haveStory() ? $store.dispatch('storyBoxOpener', story.storyBox) : mediaUploader.click()"
        :data-story-box="story.storyBox"
        v-if="showItem()"
    >
        <div>
            <div class="list-item-image" :class="!haveStory() ? 'not-story' : ''">
                <img :src="story.storyBoxImage" :alt="story.storyBox">
            </div>
            <div v-if="!haveStory()">
                <createStoryIcon />
            </div>
            <div class="list-item-story-box">
                {{story.storyBox}}
            </div>
        </div>
    </li>
</template>

<script>
import createStoryIcon from '@/icons/createStory.vue';
export default {
    name: "listItem",
    components: {
        createStoryIcon
    },
    props: ["story"],
    data: () => ({
        mediaUploader: null,
        seen: false
    }),
    async mounted() {
        let { mediaUploader } = await this.$store.dispatch('getElements');
        this.mediaUploader = mediaUploader;
        
        if (this.story.currentUser && this.story.storyItems.length == 0) return;
        let seenCookie = await this.$store.dispatch('cookies/get', 'seen');
        if (seenCookie && seenCookie[this.story.storyBox] && seenCookie[this.story.storyBox].seen) {
            this.seen = true;
        }
    },
    methods: {
        showItem() {
            if (this.$root.options && !this.$root.options.creator) {
                return this.story.storyItems.length != 0 ? true : false;
            } else {
                if (this.story.currentUser) return true;
                return this.story.storyItems.length != 0 ? true : false;
            }
        },
        haveStory() {
            if (this.$root.options && !this.$root.options.creator) return true;
            return this.story.currentUser && this.story.storyItems.length == 0 ? false : true;
        },
    }
}
</script>

<style lang="scss" scoped>
    .list-item{
        display: inline-block;
        margin-left: 7px;
        margin-right: 7px;
        cursor: pointer;
        margin-bottom: 5px;
        transition: all 100ms linear;
        position: relative;
        &:active{
            transform: scale(0.9);
            transition: all 100ms linear;
        }
        .list-item-image {
            width: 66px;
            height: 66px;
            border-radius: 50%;
            overflow: hidden;
            box-sizing: border-box;
            margin-bottom: 3px;
            &:not(.not-story) {
                padding: 2px;
                background: radial-gradient(ellipse at 70% 70%,#ee583f 8%,#d92d77 42%,#bd3381 58%);
            }
            img{
                width: 100%;
                height: 100%;
                border-radius: 50%;
                border: 2px solid #fff;
                box-sizing: border-box;
            }
        }
        .list-item-story-box{
            font-family: 'Segoe UI Regular';
            font-weight: 400;
            letter-spacing: .01em;
            max-width: 74px;
            min-width: 66px;
            color: #262626;
            display: block;
            font-size: 12px;
            overflow: hidden;
            text-align: center;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        &.seen{
            opacity: .75;
            .list-item-story-box {
                color: #999;
            }
            .list-item-image {
                background: #999;
                padding: 1px;
                img {
                    border: 3px solid #fff;
                }
            }
        }
    }
</style>