const stories = {
    namespaced: true,
    state: {
        listCopy: [],
        list: []
    },
    mutations: {
        setList(state, list) {
            state.list = list;
        },
        setListCopy(state, listCopy) {
            state.listCopy = listCopy;
        },
    },
    actions: {
        async orderByCookies({state, dispatch, commit, rootState}) {
            let listCopy = state.listCopy;
            let notSeenStories = [], hasSeenStories = [];
            let cookies =  await dispatch('cookies/get', 'seen', {root: true});
            if (!cookies) return;
            if (Object.keys(cookies).length == 0) return;
            await bpStory.forEach({
                array: listCopy,
                callback: async (story) => {
                    let storyCookie = cookies[story.storyBox];
                    if (storyCookie) {
                        if (storyCookie.seen && story.storyItems.length != 0) {
                            story.storyItems.map((storyItem, index) => {
                                let createdAt = new Date(storyItem.createdAt);
                                let lastSeenDate = new Date(storyCookie.lastSeenDate);
                                if (storyCookie.seen && createdAt > lastSeenDate) {
                                    storyCookie.activeStoryId = storyItem.storyId;
                                    storyCookie.activeStoryOrder = index; 
                                    delete storyCookie.seen;
                                }
                            });
                            dispatch(
                                'cookies/updateSeen', 
                                {storyBox: story.storyBox, storyCookie}, 
                                {root: true}
                            );
                            if (storyCookie.seen && !story.currentUser) {
                                story.lastLookDate = storyCookie.lastLookDate;
                                hasSeenStories.push(story);
                            } else {
                                notSeenStories.push(story);
                            }
                        } else {
                            notSeenStories.push(story);
                        }
                    } else {
                        notSeenStories.push(story);
                    }
                    return {notSeenStories, hasSeenStories};
                },
                done: ({hasSeenStories, notSeenStories}) => {
                    hasSeenStories.sort(function (a, b) {
                        return (new Date(a.lastLookDate)) - (new Date(b.lastLookDate));
                    });
                    if (hasSeenStories.length != 0) {
                        let newStories = notSeenStories.concat(hasSeenStories);
                        commit('setList', newStories);
                        rootState.EventBus.$emit('updateStories', newStories);
                        dispatch('randomKey', {}, {root: true});
                    }
                }
            });
        },
        setList({commit}, list) {
            commit('setList', list);
        },
        setListCopy({commit}, listCopy) {
            commit('setListCopy', listCopy);
        },
        setListAll({commit}, list) {
            commit('setList', list);
            commit('setListCopy', list);
        },
    },
    getters: {
        getList: state => state.list
    }
}

export default stories;