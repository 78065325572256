<template>
    <div 
        class="bpstory-container" 
        :id="$root.bpStoryId" 
        :key="$root.bpStoryId"
    >
        <div v-if="loaded">
            <storyList v-if="stories" :key="randomKey" :stories="stories" />
            <storyViewer v-if="stories" :key="randomKey+10" :stories="stories" />
            <storyCreator v-if="showCreator() && stories" />
            <div v-if="message">
                {{message}}
            </div>
        </div>
        <div v-else>
            <contentLoader />
        </div>
    </div>
</template>

<script>
import storyList from '@/components/list/storyList.vue';
import storyViewer from '@/components/viewer/storyViewer.vue';
import storyCreator from '@/components/creator/storyCreator.vue';
import contentLoader from '@/components/contentLoader.vue';
export default {
    name: 'App',
    components: {
        storyList,
        storyViewer,
        storyCreator,
        contentLoader
    },
    data: () => ({
        stories: null,
        loaded: false,
        message: null
    }),
    methods: {
        createWaitingElement() {
            setTimeout(() => {
                let bpstoryWaiting = document.createElement('div');
                bpstoryWaiting.classList.add('bpstory-waiting');
                let div = document.createElement('div');
                div.innerHTML = this.$store.getters.multiLang.waiting;
                bpstoryWaiting.appendChild(div);
                if (document.body.querySelector('.bpstory-waiting')) {
                    document.body.querySelector('.bpstory-waiting').remove();
                }
                document.body.appendChild(bpstoryWaiting);
            }, 1000)
        },
        showCreator() {
            if (!this.$root.options) return false;
            return this.$root.options && this.$root.options.creator ? true : false;
        },
        getStories() {
            let param = 'getStoriesList';
            if (this.$root.options && this.$root.options.getStoriesParameter) {
                param = this.$root.options.getStoriesParameter;
            }
            return new Promise((resolve) => {
                this.axios.get(`?action=${param}`)
                .then(({data}) => {
                    if (data.data) {
                        this.stories = data.data;
                        this.$store.dispatch('stories/setListAll', data.data);
                    }
                    this.createWaitingElement();
                    if (data.message) this.message = data.message;
                })
                .catch((err) => {
                    if (err.response.data.message) alert(err.response.data.message);
                })
                .then(async () => {
                    if (this.stories) {
                        await this.$store.dispatch('stories/orderByCookies');
                        this.stories = this.$store.getters['stories/getList'];
                    }
                    this.loaded = true;
                    resolve();
                });
            });
        },
        async refresh() {
            this.loaded = false;
            await this.getStories();
            this.$store.dispatch('randomKey');
        }
    },
    created() {
        if (this.$root.options && this.$root.options.refresh) {
            setInterval(this.refresh, (this.$root.options.refresh * 60000));
        }

        this.$root.EventBus.$on('updateStories', (stories) => {
            this.stories = stories;
        });
        
        this.$root.EventBus.$on('refresh', this.refresh);
    },
    async beforeCreate() {

        // set custom settings
        if (this.$root.options) {
            if (this.$root.options.multiLang) {
                let multiLang = this.$store.getters.multiLang;
                let newMultiLang = Object.assign(multiLang, this.$root.options.multiLang);
                this.$store.dispatch('setCustomSetting', {
                    stateName: 'multiLang',
                    newStateData: newMultiLang
                });
            }
            if (this.$root.options.supportedMediaTypes) {
                this.$store.dispatch('setCustomSetting', {
                    stateName: 'supportedMediaTypes',
                    newStateData: this.$root.options.supportedMediaTypes
                });
            }
            if (this.$root.options.maxFileSize) {
                this.$store.dispatch('setCustomSetting', {
                    stateName: 'maxFileSize',
                    newStateData: this.$root.options.maxFileSize
                });
            }
            if (this.$root.options.transitionTime) {
                this.$store.dispatch('autoTransition/setTransitionTime', this.$root.options.transitionTime);
            }
        }

        // create cookies object
        if (!await this.$store.dispatch('cookies/isKey')) {
            this.$store.dispatch('cookies/set', {});
        }

        this.getStories();
    },
    computed: {
        randomKey() {
            return this.$store.getters.randomKey;
        }
    }
};
</script>

<style lang="scss">
    @font-face {
        font-family: 'Segoe UI Regular';
        font-style: normal;
        font-weight: normal;
        src: local('Segoe UI Regular'), url('assets/fonts/segoe-ui.woff') format('woff');
    }
    .bpstory-waiting {
        position: fixed;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,.7);
        top: 0;
        left: 0;
        display: none;
        align-items: center;
        justify-content: center;
        z-index: 99999999999999;
        &.opened {
            display: flex;
        }
        div {
            background: #fff;
            border-radius: 8px;
            padding: 10px 15px;
            font-family: 'Segoe UI Regular';
            box-shadow: 0px 0px 15px 2px rgba(0,0,0,0.2);
            font-size: 18px;
        }
    }
</style>