const activeStory = {
    namespaced: true,
    state: {
        mediaType: null,
    },
    mutations: {
        setMediaType(state, mediaType) {
            state.mediaType = mediaType;
        },
    },
    actions: {
        setMediaType({commit}, video) {
            let mediaType = 'image';
            if (video) mediaType = 'video';
            commit('setMediaType', mediaType);
        },
        async hasSeen({dispatch, rootState}) {
            let { activeStory, activePointer, video } = await dispatch('getElements', {}, {root: true});
            clearInterval(rootState.autoTransition.interval);
            if (video) video.pause();
            if (activeStory && bpStory.isElement(activeStory.nextSibling)) {
                activeStory.classList.remove('active');
                activeStory.nextSibling.classList.add('active');
                if (bpStory.isElement(activePointer.nextSibling)) {
                    activePointer.nextSibling.classList.add('active');
                    activePointer.nextSibling.classList.add('stop');
                    activePointer.classList.remove('active');
                    activePointer.classList.add('seen');
                }
            }
        },
        async setPublishTime({dispatch}) {
            let { activeStory, activeStoryBox } = await dispatch('getElements', {}, {root: true});
            let publishTime = activeStory.getAttribute('data-publish-time');
            activeStoryBox.querySelector('.publish-time').innerHTML = publishTime;
        },
        async reset({dispatch}) {
            let { activeStory, allStories, pointers, video } = await dispatch('getElements', {}, {root: true});
            if (video) video.pause();
            if (activeStory && !bpStory.isElement(activeStory.nextSibling)) {
                bpStory.forEach({
                    array: allStories,
                    callback: (story) => {
                        story.classList.remove('active');
                    },
                    done: () => {
                        allStories[0].classList.add('active');
                    }
                });

                bpStory.forEach({
                    array: pointers,
                    callback: (pointer) => {
                        pointer.classList.remove('seen');
                        pointer.classList.remove('active');
                        pointer.classList.remove('stop');
                    },
                    done: () => {
                        pointers[0].classList.add('active');
                        pointers[0].classList.add('stop');                    
                    }
                });
            }
        },
    },
    getters: {
        mediaType: state => state.mediaType,
    }
}

export default activeStory;