import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

import modules from './modules';
import bpStory from "@/helpers.js";

export default function makeStore(bpStoryId, EventBus) {
    return new Vuex.Store({
        devtools: false,
        state: {
            EventBus,
            bpStoryId,
            randomKey: bpStory.randomKey(),
            isMobile: bpStory.isMobile(),
            imageEditor: null,
            controllers: {
                pause: false,
                mute: true
            },
            maxFileSize: 5,
            multiLang: {
                seeMore: 'See More',
                unsupportedMedia: 'Unsupported Media Type',
                maxFileSizeWarning: 'The file you are trying to upload exceeds the maximum allowed file size!',
                enterURLAddress: 'Please enter a URL address!',
                wantAddURL: 'Want to add a URL link to your story?',
                invalidURL: 'You have entered an invalid URL format, please enter a valid URL format.',
                publish: 'Publish',
                selectNewMedia: 'Select new media',
                close: 'Close',
                createNewStory: 'Create new story',
                confirmDelete: 'Are you sure you want to delete your story?',
                waiting: 'Please wait...'
            },
            supportedMediaTypes: [
                'mp4',
                'webm',
                'ogg',
                'jpg',
                'jpeg',
                'png',
                'gif',
                'ico',
                'webp'
            ],
            videoTypes: [
                'mp4',
                'webm',
                'ogg'
            ],
            imageTypes: [
                'jpg',
                'jpeg',
                'png',
                'gif',
                'ico',
                'webp'
            ],
        },
        mutations: {
            setControllersPause(state, status) {
                state.controllers.pause = status;
            },
            setControllersMute(state, status) {
                state.controllers.mute = status;
            },
            setBpStoryId(state, id) {
                state.bpStoryId = id;
            },
            setIsMobile(state, status) {
                state.isMobile = status;
            },
            setImageEditor(state, imageEditor) {
                state.imageEditor = imageEditor;
            },
            setRandomKey(state, random) {
                state.randomKey = random;
            },
            setCustomSetting(state, {stateName, newStateData}) {
                state[stateName] = newStateData;
            }
        },
        actions: {
            async createStory({}) {
            },
            randomKey({commit}) {
                commit('setRandomKey', bpStory.randomKey());
            },
            on({state}, {key, val}) {
                state.EventBus.$on(key, val);
            },
            emit({state}) {
                state.EventBus.$emit(key, val);
            },
            async storyBoxOpener({dispatch, rootState}, storyBox) {
    
                let { storyViewer, activeStoryBox, loadingAnimation, storyBoxGeneral, allStoryBoxes } = await dispatch('getElements');
                
                if (!storyViewer.classList.contains('thatClass')) {
                    storyViewer.classList.add('opened');
                    document.querySelector('body').style.overflow = 'hidden';
                    
                }
    
                if (activeStoryBox) {
                    activeStoryBox.classList.remove('active');
                }

                let newStoryBox = true;
                if (activeStoryBox && storyBox == activeStoryBox.getAttribute('data-story-box')) {
                    newStoryBox = false;
                }
    
                activeStoryBox = storyViewer.querySelector(".story-box[data-story-box='"+storyBox+"']");
                activeStoryBox.classList.add('active');
                let activeStory = activeStoryBox.querySelector('.story-item.active');
    
                dispatch('activeStory/setMediaType', activeStory.querySelector('video'), {root: true});
                dispatch('activeStory/setPublishTime', {}, {root: true});

                loadingAnimation.classList.remove('closed');
                storyBoxGeneral.classList.add('loading');
                activeStoryBox.classList.add('loading');

                let storyCount = Math.round((allStoryBoxes.length / 40));
                if (storyCount == 0) storyCount++;
                let animationTime = 500 * storyCount;
                
                function open() {
                    loadingAnimation.classList.add('closed');
                    storyBoxGeneral.classList.remove('loading');
                    activeStoryBox.classList.remove('loading');
                    if (rootState.autoTransition.closePause) {
                        dispatch('autoTransition/closePause', false, {root: true});
                        dispatch('autoTransition/start', {}, {root: true});
                        dispatch('setControllersPause', false);
                    } else {
                        if (!rootState.autoTransition.paused) {
                            dispatch('autoTransition/start',  {}, {root: true});
                        }
                    }
                    dispatch('cookies/setSeen', {activeStoryBox, activeStory}, {root: true});
                }
                if (newStoryBox) {
                    dispatch('resizer/resizeStoryBoxes', {}, {root: true});
                    setTimeout(() => open(), (animationTime + 450));
                } else {
                    setTimeout(() => open(), animationTime);
                }
            },
            getElements({state}) {
                let bpStory = document.getElementById(state.bpStoryId);
                let waiting = document.querySelector('.bpstory-waiting');
                let storyViewer = document.querySelector('.bpstory-viewer');
                let storyCreator = document.querySelector('.bpstory-creator');
                let mediaUploader = storyCreator ? storyCreator.querySelector('#media-uploader') : null;
                let loadingAnimation = storyViewer.querySelector('.animation-container');
                let viewerContainer = storyViewer.querySelector('.viewer-container');
                let storyBoxGeneral = storyViewer.querySelector('.story-box-general');
                let activeStoryBox = storyBoxGeneral.querySelector('.story-box.active');
                let allStoryBoxesNotActive = storyBoxGeneral.querySelectorAll('.story-box:not(.active)');
                let allStoryBoxes = storyBoxGeneral.querySelectorAll('.story-box');
                let activeStory = activeStoryBox ? activeStoryBox.querySelector('.story-item.active') : null;
                let pointers = activeStoryBox ? activeStoryBox.querySelectorAll('.pointer') : null;
                let activePointer = activeStoryBox ? activeStoryBox.querySelector('.pointer.active') : null;
                let activePointerDiv = activePointer ? activePointer.querySelector('div') : null;
                let video = activeStory ? activeStory.querySelector('video') : null;
                let allStories = activeStoryBox ? activeStoryBox.querySelectorAll('.story-item') : null;
                let prevBtn = viewerContainer.querySelector('.prev-btn');
                let nextBtn = viewerContainer.querySelector('.next-btn');
                let directionBtn = viewerContainer.querySelectorAll('.direction-btn');
                let storyList = bpStory.querySelector('.story-list');
                let videoEditor = document.querySelector(".video-editor");
                return {
                    bpStory,
                    waiting,
                    storyViewer,
                    storyCreator,
                    mediaUploader,
                    viewerContainer,
                    storyBoxGeneral,
                    allStoryBoxes,
                    allStoryBoxesNotActive,
                    activeStoryBox,
                    allStories,
                    activeStory,
                    activePointer,
                    prevBtn,
                    nextBtn,
                    directionBtn,
                    storyList,
                    loadingAnimation,
                    video,
                    pointers,
                    activePointerDiv,
                    videoEditor
                };
            },
            setControllersPause({commit}, status) {
                commit('setControllersPause', status);
            },
            setControllersMute({commit}, status) {
                commit('setControllersMute', status);
            },
            setBpStoryId({commit}, id) {
                commit('setBpStoryId', id);
            },
            isMobile({commit}) {
                let status = bpStory.isMobile();
                commit('setIsMobile', status);
                return status;
            },
            setImageEditor({commit}, imageEditor) {
                commit('setImageEditor', imageEditor);
            },
            setCustomSetting({commit}, {stateName, newStateData}) {
                commit('setCustomSetting', {stateName, newStateData});
            }
        },
        getters: {
            bpStoryId: state => state.bpStoryId,
            multiLang: state => state.multiLang,
            controllers: state => state.controllers,
            isMobile: state => state.isMobile,
            supportedMediaTypes: state => state.supportedMediaTypes,
            imageEditor: state => state.imageEditor,
            imageTypes: state => state.imageTypes,
            videoTypes: state => state.videoTypes,
            randomKey: state => state.randomKey,
            maxFileSize: state => state.maxFileSize
        },
        modules
    });
}