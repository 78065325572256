<template>
    <div class="story-box-opener-container">
        <div 
            class="story-box-opener"
            :class="seen ? 'seen' : ''" 
            @click.prevent="storyBoxOpener(story.storyBox)"
            :data-story-box="story.storyBox"
        >
            <div>
                <div class="user-image">
                    <img :src="story.storyBoxImage" :alt="story.storyBox">
                </div>
                <div class="story-box-name">
                    {{story.storyBox}}
                </div>
            </div>
            <span>{{story.lastStoryPublishTime}}</span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'storyBoxOpener',
    props: ['story'],
    data: () => ({
        seen: false
    }),
    async mounted() {
        let seenCookie = await this.$store.dispatch('cookies/get', 'seen');
        if (seenCookie && seenCookie[this.story.storyBox] && seenCookie[this.story.storyBox].seen) {
            this.seen = true;
        }
    },
    methods: {
        async storyBoxOpener(storyBox) {
            this.$store.dispatch('activeStory/hasSeen');
            this.$store.dispatch('activeStory/reset');
            this.$store.dispatch('storyBoxOpener', storyBox);
        }
    }
}
</script>

<style lang='scss' scoped>
    .story-box-opener-container {
        position: absolute;
        background: rgba(0, 0, 0, .6);
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 7;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .story-box-opener{
        display: inline-block;
        margin-left: 7px;
        margin-right: 7px;
        cursor: pointer;
        margin-bottom: 5px;
        transition: all 100ms linear;
        position: relative;
        span {
            position: absolute;
            font-family: 'Segoe UI Regular';
            color: #fff;
            font-weight: 400;
            font-size: 12px;
            bottom: -20px;
            width: 100%;
            display: block;
            text-align: center;
        }
        &:active{
            transform: scale(0.9);
            transition: all 100ms linear;
        }
        .user-image {
            width: 66px;
            height: 66px;
            border-radius: 50%;
            overflow: hidden;
            padding: 2px;
            background: radial-gradient(ellipse at 70% 70%,#ee583f 8%,#d92d77 42%,#bd3381 58%);
            box-sizing: border-box;
            margin-bottom: 3px;
            img{
                width: 100%;
                height: 100%;
                border-radius: 50%;
                border: 2px solid #fff;
                box-sizing: border-box;
            }
        }
        .story-box-name{
            font-family: 'Segoe UI Regular';
            font-weight: 400;
            letter-spacing: .01em;
            max-width: 74px;
            min-width: 66px;
            color: #fff;
            display: block;
            font-size: 16px;
            overflow: hidden;
            text-align: center;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        &.seen{
            opacity: .75;
            .story-box-name {
                color: rgb(228, 228, 228);
            }
            .user-image {
                background: #999;
            }
        }
    }
</style>