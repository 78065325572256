const cookies = {
    namespaced: true,
    actions: {
        async set({rootState, dispatch}, newCookie) {
            let cookies = await dispatch('get');
            cookies = Object.assign(cookies, newCookie);
            this._vm.$cookies.set(rootState.bpStoryId, cookies);
        },
        get({rootState}, key) {
            let cookies = this._vm.$cookies.get(rootState.bpStoryId);
            if (!cookies) cookies = {};
            if (key) {
                return cookies[key];
            } else {
                return cookies;
            }
        },
        async isKey({rootState, dispatch}, key) {
            if (key) {
                let cookies = await dispatch('get');
                return cookies[key] ? true : false;
            } else {
                return this._vm.$cookies.isKey(rootState.bpStoryId);
            }
            
        },
        async updateSeen({dispatch}, {storyBox, storyCookie}) {
            let cookies = await dispatch('get');
            if (cookies.seen) {
                cookies.seen[storyBox] = storyCookie;
            }
            dispatch('set', cookies);
        },
        async setSeen({dispatch}, {activeStory, activeStoryBox}) {
            let { storyList } = await dispatch('getElements', {}, {root: true});

            let cookies = await dispatch('get');

            let storyBox = activeStoryBox.getAttribute('data-story-box');
            let order = parseInt(activeStory.getAttribute('data-order'));
            let storyId = parseInt(activeStory.getAttribute('data-story-id'));
            let allStories = activeStoryBox.querySelectorAll('.story-item');

            if (bpStory.isElement(activeStory.nextSibling)) {
                var activeStoryId = parseInt(activeStory.nextSibling.getAttribute('data-story-id'));
                var activeStoryOrder = parseInt(activeStory.nextSibling.getAttribute('data-order'));
            } else {
                var activeStoryId = parseInt(allStories[0].getAttribute('data-story-id'));
                var activeStoryOrder = 0;
            }

            let storyCookie = { activeStoryId, activeStoryOrder };

            if (cookies.seen) {
                if (cookies.seen[storyBox]) {
                    cookies.seen[storyBox] = Object.assign(cookies.seen[storyBox], storyCookie);
                } else {
                    cookies.seen = Object.assign(cookies.seen, { [storyBox]: storyCookie });
                }
            } else {
                cookies.seen = { [storyBox]: storyCookie };
            }
            
            cookies.seen[storyBox].lastLookDate = new Date(); 
            
            if (order == (allStories.length-1)) {
                cookies.seen[storyBox].seen = true;
                cookies.seen[storyBox].lastStoryId = storyId;
                cookies.seen[storyBox].lastOrder = order; 
                cookies.seen[storyBox].lastSeenDate = new Date(); 
                let listItem = storyList.querySelector(".list-item[data-story-box='"+storyBox+"']");
                let storyBoxOpener = activeStoryBox.querySelector(".story-box-opener[data-story-box='"+storyBox+"']");
                listItem.classList.add('seen');
                storyBoxOpener.classList.add('seen');
            }
            
            dispatch('set', cookies);
        },
    }
}

export default cookies;