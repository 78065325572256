<template>
    <div class="header-menu">
        <div 
            class="delete-my-story" 
            @click="deleteMyStory" 
            v-if="story.currentUser && $root.options && $root.options.creator"
        >
            <div class="icon-box">
                <deleteIcon />    
            </div>
        </div>
        <div class="movement">
            <div class="icon-box play" @click="pause(false)" v-show="$store.getters.controllers.pause">    
                <playIcon />
            </div>
            <div class="icon-box pause" @click="pause(true)" v-show="!$store.getters.controllers.pause">
                <pauseIcon />
            </div>
        </div>
        <div class="sound" v-show="$store.getters['activeStory/mediaType'] == 'video'">
            <div class="icon-box audible" @click="mute(true)" v-show="!$store.getters.controllers.mute">
                <audibleIcon />
            </div>
            <div class="icon-box mute" @click="mute(false)" v-show="$store.getters.controllers.mute">
                <muteIcon />
            </div>
        </div>
        <div 
            class="menu-btn" 
            @click="showMenu = true" 
            v-if="showCreator()"
        >
            <div class="icon-box">
                <menuBtnIcon />    
            </div>
        </div>
        <div 
            class="modal-menu" 
            :class="showMenu ? 'opened' : ''"
            v-if="showCreator()"
        >
            <ul class="modal-menu-list">
                <li 
                    @click="createNewStory" 
                    v-if="showCreator()"
                >
                    {{$store.getters.multiLang.createNewStory}}
                </li>
                <li @click="showMenu = false">{{$store.getters.multiLang.close}}</li>
            </ul>
        </div>
    </div>
</template>

<script>
import audibleIcon from "@/icons/audible.vue";
import muteIcon from "@/icons/mute.vue";
import playIcon from "@/icons/play.vue";
import pauseIcon from "@/icons/pause.vue";
import menuBtnIcon from "@/icons/menuBtn.vue";
import deleteIcon from "@/icons/delete.vue";
export default {
    name: 'headerMenu',
    components: {
        audibleIcon,
        muteIcon,
        playIcon,
        pauseIcon,
        menuBtnIcon,
        deleteIcon
    },
    props: ['story'],
    data: () => ({
        showMenu: false,
        paused: false
    }),
    watch: {
        showMenu(status) {
            this.pausedIsFalsePause(status);
        },
    },
    methods: {
        showCreator() {
            return this.$root.options && this.$root.options.creator && this.$store.getters['stories/getList'][0].currentUser ? true : false;
        },
        pausedIsFalsePause(status) {
            if (!this.paused) {
                this.$store.dispatch('setControllersPause', status);
                this.$store.dispatch('autoTransition/disabled', status);
                if (status) {
                    this.$store.dispatch('autoTransition/paused', true);
                }
            }
        },
        async deleteMyStory() {
            this.pausedIsFalsePause(true);
            setTimeout(async () => {
                let confirmDelete = confirm(this.$store.getters.multiLang.confirmDelete);
                this.pausedIsFalsePause(false);
                if (confirmDelete) {
                    let { activeStory, activePointer } = await this.$store.dispatch('getElements');
                    let storyId = activeStory.getAttribute('data-story-id');
                    let formData = new FormData();
                    formData.append('storyId', storyId) ;
                    this.axios.post('?action=deleteMyStory', formData)
                    .then(async ({data}) => {
                        if (data.message) alert(data.message);
                        if (data.success) {
                            let remove = true;
                            if (bpStory.isElement(activeStory.nextSibling)) {
                                this.$root.EventBus.$emit('directionBtnClick', true);
                            } else if (bpStory.isElement(activeStory.previousSibling)) {
                                this.$root.EventBus.$emit('directionBtnClick', false);
                            } else {
                                remove = false;
                                this.$root.EventBus.$emit('closeViewer');
                                setTimeout(() => this.$root.EventBus.$emit('refresh'), 150);
                            }
                            if (remove) {
                                setTimeout(() => {
                                    activeStory.remove();
                                    activePointer.remove();
                                }, 100);
                            }
                        }
                    })
                    .catch((err) => {
                        if (err.response.data.message) alert(err.response.data.message);
                    });
                }
            }, 100);
        },
        async createNewStory() {
            let { mediaUploader } = await this.$store.dispatch('getElements');
            if (!mediaUploader) return;
            this.showMenu = false;
            setTimeout(() => {
                mediaUploader.click();
                this.$root.EventBus.$emit('closeViewer');
            }, 100);
        },
        pause(status) {
            this.paused = status;
            this.$store.dispatch('setControllersPause', status);
            this.$store.dispatch('autoTransition/disabled', status);
            if (status) {
                this.$store.dispatch('autoTransition/paused', true);
            }
        },
        async mute(status) {
            let { video } = await this.$store.dispatch('getElements');
            video.muted = status;
            this.$store.dispatch('setControllersMute', status);
        }
    }
}
</script>

<style lang="scss" scoped>
    .header-menu {
        position: absolute;
        z-index: 2;
        top: 35px;
        right: 15px;
        font-family: 'Segoe UI Regular';
        display: flex;
        align-items: center;
        height: 30px;
        z-index: 9;
        .icon-box {
            height: 30px;
            width: 25px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
        }
        .modal-menu {
            position: fixed;
            z-index: 10;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            background: rgba(0,0,0,.3);
            display: flex;
            align-items: center;
            justify-content: center;
            opacity: 0;
            visibility: hidden;
            transform: scale(1.1);
            transition: all 150ms linear;
            &.opened {
                opacity: 1;
                visibility: visible;
                transform: scale(1);
                transition: all 150ms linear;
            }
            .modal-menu-list {
                margin: 0 15px;
                padding: 0;
                list-style: none;
                border-radius: 12px;
                background: #fff!important;
                display: inline-block;
                overflow: hidden;
                width: 320px;
                font-size: 16px;
                li {
                    padding: 15px 30px;
                    text-align: center;
                    cursor: pointer;
                    font-weight: 550;
                    &:hover {
                        background-color: rgba(0,0,0,.1)!important;
                    }
                    &:not(:last-child) {
                        border-bottom: 1px solid #dbdbdb;
                    }
                }
            }
        }
    }
</style>