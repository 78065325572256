const autoTransition = {
    namespaced: true,
    state: {
        paused: false,
        interval: null,
        closePause: false,
        transitionTime: 5
    },
    mutations: {
        setPaused(state, status) {
            state.paused = status;
        },
        setInterval(state, interval) {
            state.interval = interval;
        },
        setClosePause(state, status) {
            state.closePause = status;
        },
        setTransitionTime(state, transitionTime) {
            state.transitionTime = transitionTime;
        }
    },
    actions: {
        reset: ({state}) => clearInterval(state.interval),
        async start({commit, dispatch, state, rootState}) {

            let { 
                activeStory, 
                activePointer, 
                activeStoryBox,
                activePointerDiv,
                video
            } = await dispatch('getElements', {}, {root: true});

            // Get default story time
            let transitionTime = activeStory.hasAttribute('data-transition-time') ? activeStory.getAttribute('data-transition-time') : state.transitionTime;
            transitionTime *= 1000;

            if (video) {
                function playVideo() {
                    video.play();
                    transitionTime = Math.round(video.duration) * 1000;
                    video.muted = rootState.controllers.mute;
                    transition();
                }
                if (video.duration) {
                    playVideo();
                } else {
                    video.onloadedmetadata = playVideo;
                }
            } else {
                transition();
            }            
            
            function transition() {
                
                // set story type
                dispatch('activeStory/setMediaType', video, {root: true});

                activePointer.classList.remove('stop');
                activePointerDiv.style.animationDuration = transitionTime + 'ms';
                activePointerDiv.style.animationPlayState = 'running';
                activePointer.classList.add('active');

                if (state.paused) {
                    let pointerWidth = bpStory.getRealVal(activePointerDiv.parentNode, 'width')
                    let pointerDivWidth = bpStory.getRealVal(activePointerDiv, 'width')
                    let percent = pointerDivWidth * 100 / pointerWidth;
                    transitionTime = transitionTime - (transitionTime * percent / 100);
                    dispatch('paused', false);
                }
                if (video) video.currentTime = 0;

                commit('setInterval', setInterval(() => {
                    if (!activeStoryBox.nextSibling && !bpStory.isElement(activeStory.nextSibling)) {
                        clearInterval(state.interval);
                    } else if (state.paused) {
                        clearInterval(state.interval);
                    } else {
                        rootState.EventBus.$emit('directionBtnClick', true);
                    }
                }, transitionTime));
            }

        },
        async disabled({dispatch, state}, status) {
            let { activePointerDiv, video } = await dispatch('getElements', {}, {root: true});
            if (status) {
                if (video) video.pause();
                activePointerDiv.style.animationPlayState = 'paused';
                clearInterval(state.interval);
            } else {
                dispatch('start');
            }
        },
        paused({commit}, status) {
            commit('setPaused', status);
        },
        closePause({commit}, status) {
            commit('setClosePause', status);
        },
        setTransitionTime({commit}, transitionTime) {
            commit('setTransitionTime', transitionTime);
        }
    },
    getters: {
        paused: state => state.paused,
        interval: state => state.interval,
        closePause: state => state.closePause,
        transitionTime: state => state.transitionTime,
    }
};

export default autoTransition;