<template>
    <div class="pointers">
        <div 
            class="pointer" 
            v-for="(storyItem, index) in story.storyItems"
            :key="index"
            :data-order="index"
            :data-story-id="storyItem.storyId"
            :class="getStatusByCookies(story.storyBox, storyItem, index)"
        >
            <div></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'pointers',
    props: ['story'],
    data: () => ({
        activeStory: false,
        seenCookie: null
    }),
    mounted() {
        if (!this.activeStory) {
            let bpStory = document.getElementById(this.$root.bpStoryId);
            let activeStoryBox = bpStory.querySelector('.story-box[data-story-box="'+this.story.storyBox+'"]');
            let activePointer = activeStoryBox.querySelector(".pointer[data-order='0']");
            activePointer.classList.add('active');
            activePointer.classList.add('stop');
            activePointer.classList.remove('seen');
        }
    },
    async beforeCreate() {
        let seenCookie = await this.$store.dispatch('cookies/get', 'seen');
        this.seenCookie = seenCookie;
    },
    methods: {
        getStatusByCookies(storyBox, storyItem, index) {
            let className = '';

            if (this.activeStory && this.activeStory == storyItem.storyId) return 'active stop ';

            let seenCookie = this.seenCookie;
            if (seenCookie && seenCookie[storyBox]) {
                let storyCookie = seenCookie[storyBox];
                if (storyCookie.activeStoryId == storyItem.storyId) {
                    className += 'active stop ';
                } else if (!this.activeStory && storyCookie.activeStoryOrder == index) {
                    className += 'active stop ';
                } else {
                    className += index < storyCookie.activeStoryOrder  ? 'seen ' : '';
                }
            } else if (index == 0) {
                className += 'active stop ';
            }

            if (className.indexOf('active') !== -1) {
                this.activeStory = storyItem.storyId;
            } 

            return className;
        },
    }
}
</script>

<style lang="scss" scoped>
    .pointers {
        position: absolute;
        z-index: 2;
        width: calc(100% - 30px);
        top: 15px;
        left: 15px;
        height: 10px;
        display: flex;
        align-items: center;
        .pointer {
            width: 100%;
            height: 2px;
            background: rgba(255,255,255,.6);
            margin-left: 2px;
            margin-right: 2px;
            border-radius: 16px;
            overflow: hidden;
            div {
                width: 0%;
                height: 100%;
                background: #fff;
                display: none;
            }
            &.active:not(.stop) {
                div {
                    animation-name: pointerWidth0To100;
                    animation-timing-function: linear;
                    animation-fill-mode: forwards;
                    display: block;
                }
            }
            &.seen {
                div {
                    display: block!important;
                    width: 100%!important;
                }
            }
        }
    }

    @keyframes pointerWidth0To100 {
        from {
            width: 0;
        }
        to {
            width: 100%;
        }
    }
</style>