let resizeAfterProcessTimer;

const resizer = {
    namespaced: true,
    state: {
        heightRatio: 597,
        activeStoryBoxWidth: 335,
        notActiveStoryBoxWidth: 140,
        activeStoryBoxHeightPercent: 94,
        notActiveStoryBoxHeightPercent: 38.113,
        ratio: 1.78208955224,
    },
    mutations: {
        setActiveStoryBoxWidth(state, width) {
            state.activeStoryBoxWidth = width;
        },
        setNotActiveStoryBoxWidth(state, width) {
            state.notActiveStoryBoxWidth = width;
        },
    },
    actions: {
        resizeStoryBoxesTimer({dispatch}, time = 450) {
            setTimeout(() => {
                dispatch('resizeStoryBoxes');
            }, time);
        },
        async resizeStoryBoxes({dispatch, state}) {
            let { activeStoryBox, allStoryBoxesNotActive } = await dispatch('getElements', {}, {root: true});

            if (!activeStoryBox) return;
            
            let activeStoryBoxHeight = (window.innerHeight * state.activeStoryBoxHeightPercent / 100);
            let activeStoryBoxWidth = activeStoryBoxHeight / state.ratio;

            let isMobile = await dispatch('isMobile', {}, {root: true});
            if (isMobile) activeStoryBoxWidth = isMobile;

            activeStoryBox.style.width = activeStoryBoxWidth + 'px';
            
            clearTimeout(resizeAfterProcessTimer);

            if (allStoryBoxesNotActive.length != 0) {
                bpStory.forEach({
                    array: allStoryBoxesNotActive,
                    callback: (currentStoryBox) => {
                        let notActiveStoryBoxHeight = (window.innerHeight * state.notActiveStoryBoxHeightPercent / 100);
                        var notActiveStoryBoxWidth = notActiveStoryBoxHeight / state.ratio;
                        currentStoryBox.style.width = notActiveStoryBoxWidth + 'px';
                    },
                    done: () => {
                        resizeAfterProcessTimer = setTimeout(() => dispatch('resizeAfterProcess'), 450);
                    }
                });
            } else {
                resizeAfterProcessTimer = setTimeout(() => dispatch('resizeAfterProcess'), 450);
            }

        },
        async resizeAfterProcess({dispatch, commit}) {
            let { 
                prevBtn, 
                nextBtn,
                storyBoxGeneral, 
                viewerContainer, 
                activeStoryBox, 
                allStoryBoxesNotActive
            } = await dispatch('getElements', {}, {root: true});
            
            let order = parseInt(activeStoryBox.getAttribute('data-order'));
            if (!storyBoxGeneral.querySelector('.story-box[data-order="0"]')) order--;

            let viewerContainerWidth = bpStory.getRealVal(viewerContainer, 'width');
            let activeStoryBoxWidth = bpStory.getRealVal(activeStoryBox, 'width');

            let storyBoxMarginLeft = (viewerContainerWidth / 2) - (activeStoryBoxWidth / 2);
            
            if (order == 0) {
                storyBoxGeneral.style.marginLeft =  storyBoxMarginLeft + 'px';
            } else {
                let notActiveStoryBoxWidth = (bpStory.getRealVal(allStoryBoxesNotActive[0], 'width') + 40) * order;
                storyBoxGeneral.style.marginLeft = storyBoxMarginLeft - notActiveStoryBoxWidth + 'px';
            }

            let btnLocation = (viewerContainerWidth / 2) - (activeStoryBoxWidth / 2);

            let isMobile = await dispatch('isMobile', {}, {root: true});

            if (isMobile) {
                btnLocation += 5;
            } else {
                btnLocation -= 36;
            }

            prevBtn.style.left = nextBtn.style.right = btnLocation + 'px';
            commit('setActiveStoryBoxWidth', bpStory.getRealVal(activeStoryBox, 'width'));
            if (allStoryBoxesNotActive[0]) {
                commit('setNotActiveStoryBoxWidth', bpStory.getRealVal(allStoryBoxesNotActive[0], 'width'));
            }
        },
    },
    getters: {
        activeStoryBoxWidth: state => state.activeStoryBoxWidth,
        notActiveStoryBoxWidth: state => state.notActiveStoryBoxWidth,
    }
}

export default resizer;