import resizer from './resizer.js';
import cookies from './cookies.js';
import stories from './stories.js';
import activeStory from './activeStory.js';
import autoTransition from './autoTransition.js';

const modules = {
    resizer,
    cookies,
    stories,
    activeStory,
    autoTransition
};

export default modules;