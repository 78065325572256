<template>
    <div class="content-loader">
        <svg
            v-for="index in 8"
            :key="index"
            role="img"
            width="67"
            height="87"
            aria-labelledby="loading-aria"
            viewBox="0 0 67 87"
            preserveAspectRatio="none"
        >
            <rect
                x="0"
                y="0"
                width="100%"
                height="100%"
                clip-path="url(#clip-path)"
                style='fill: url("#fill");'
            ></rect>
            <defs>
                <clipPath id="clip-path">
                    <rect x="6" y="70" rx="3" ry="3" width="52" height="6" /> 
                    <circle cx="32" cy="35" r="25" />
                </clipPath>
                <linearGradient id="fill">
                    <stop
                        offset="0.599964"
                        stop-color="#c0c0c0"
                        stop-opacity="1"
                    >
                        <animate
                            attributeName="offset"
                            values="-2; -2; 1"
                            keyTimes="0; 0.25; 1"
                            dur="2s"
                            repeatCount="indefinite"
                        ></animate>
                    </stop>
                    <stop
                        offset="1.59996"
                        stop-color="#ffffff"
                        stop-opacity="1"
                    >
                        <animate
                            attributeName="offset"
                            values="-1; -1; 2"
                            keyTimes="0; 0.25; 1"
                            dur="2s"
                            repeatCount="indefinite"
                        ></animate>
                    </stop>
                    <stop
                        offset="2.59996"
                        stop-color="#c0c0c0"
                        stop-opacity="1"
                    >
                        <animate
                            attributeName="offset"
                            values="0; 0; 3"
                            keyTimes="0; 0.25; 1"
                            dur="2s"
                            repeatCount="indefinite"
                        ></animate>
                    </stop>
                </linearGradient>
            </defs>
        </svg>
    </div>
</template>

<script>
export default {
    name: 'contentLoader'
}
</script>

<style lang="scss" scoped>
    .content-loader {
        overflow: hidden;
        white-space: nowrap;
    }
</style>