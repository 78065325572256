import Vue from "vue";
import makeStore from './store'
import App from "./App.vue";

// load helpers
import bpStory from "./helpers.js";
window.bpStory = bpStory;

Vue.mixin({
    data: () => ({
        bpStory
    })
});

// cookies
import VueCookies from "vue-cookies";
Vue.use(VueCookies);
// set default config
Vue.$cookies.config(((60 * 60 * 24 * 365) * 5));
// set global cookie
Vue.$cookies.set('theme','default');
Vue.$cookies.set('hover-time','1s');

// axios
import axios from 'axios'
import VueAxios from 'vue-axios'
let API_URL = BPSTORY_API_URL;
API_URL += API_URL.endsWith("/") ? "" : "/";
axios.defaults.baseURL = API_URL;
Vue.use(VueAxios, axios);

Vue.config.productionTip = false;

function createInstance(bpStory) {
    let data = {};
    data.bpStoryId = bpStory.getAttribute('id');
    data.EventBus = new Vue();
    let options = bpStory.getAttribute('data-options');
    if (options) {
        data.options = JSON.parse(options);
    }
    new Vue({
        data,
        store: makeStore(data.bpStoryId, data.EventBus),
        render: (h) => h(App),
    }).$mount(`#${data.bpStoryId}`);
}

if (navigator.cookieEnabled) {
    let bpStories = document.querySelectorAll('.bpstory-placeholder');
    if (bpStories) {
        bpStories.forEach((bpStory) => {
            createInstance(bpStory);
        });
    }
} else {
    alert('Please enable cookies for the BP Story plugin to work.');
}