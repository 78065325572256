<template>
    <div class="bpstory-viewer">
        <loadingAnimation />
        <div class="close-btn" @click="closeViewer"></div>
        <div class="viewer-container">
            <directionButtons />
            <div class="story-box-general">
                <storyBox 
                    v-for="(story, index) in stories" :key="index" 
                    :index="index"
                    :story="story" 
                />
            </div>
        </div>
    </div>
</template>

<script>
import loadingAnimation from "@/components/loadingAnimation.vue";
import directionButtons from "@/components/viewer/directionButtons.vue";
import storyBox from "@/components/viewer/storyBox.vue";
export default {
    name: 'storyViewer',
    props: ['stories'],
    components: {
        loadingAnimation,
        directionButtons,
        storyBox
    },
    async mounted() {
        let { storyBoxGeneral, allStoryBoxes } = await this.$store.dispatch('getElements');
        storyBoxGeneral.style.width = allStoryBoxes.length * 375 + 'px';
        window.addEventListener('resize', () => {
            this.$store.dispatch('resizer/resizeStoryBoxes');
        });
        this.$store.dispatch('resizer/resizeStoryBoxes');
        
        this.$root.EventBus.$on('closeViewer', this.closeViewer);

        if (document.querySelector('body > .bpstory-viewer')) {
            document.querySelector('body > .bpstory-viewer').remove();
        }
        document.body.appendChild(this.$el);
    },
    methods: {
        async closeViewer() {
            let { storyViewer } = await this.$store.dispatch('getElements');
            storyViewer.classList.remove('opened');
            this.$store.dispatch('activeStory/hasSeen');
            this.$store.dispatch('stories/orderByCookies');
            this.$store.dispatch('activeStory/reset');
            this.$store.dispatch('resizer/resizeStoryBoxesTimer', 1000);
            document.querySelector('body').style.overflow = 'visible';
        },
    }
}
</script>

<style lang="scss" scoped>
    .bpstory-viewer {
        position: fixed;
        background: #1a1a1a;
        width: 100%;
        height: 100%;
        bottom: -110%;
        left: 0;
        transition: all 200ms;
        z-index: 999999999999;
        &.opened {
            transition: all 200ms;
            bottom: 0;
        }
        .close-btn {
            width:25px;
            height: 24px;
            top: 15px;
            right: 15px;
            position: absolute;
            cursor: pointer;
            &::before, &::after {
                content: '';
                display: block;
                width: 2.5px;
                height: 24px;
                border-radius: 5px;
                background: #fff;
                position: absolute;
                left: calc(50% - 2.5px / 2);
                top: 0px;
            }
            &::before {
                transform: rotate(45deg);
            }
            &::after {
                transform: rotate(-45deg);
            }
        }
        .viewer-container {
            width: 80%;
            margin-left: 10%;
            overflow: hidden;
            height: 100%;
            position: relative;
        }
        @media screen and (max-width: 450px) {
            .viewer-container {
                width: 100%;
                margin-left: 0px;
            }
        }
        .story-box-general {
            display: flex;
            align-items: center;
            height: 100%;
            margin-left: calc(50% - 172px);
            &:not(.loading) {
                transition: 350ms linear;
            }
        }
    }
    @media screen and (max-width: 450px) {
        .viewer {
            .close-btn {
                z-index: 9;
                top: 37.5px;
                right: inherit;
                left: 15px;
            }
        }
    }
</style>