import { render, staticRenderFns } from "./videoEditor.vue?vue&type=template&id=b466ebc2&scoped=true&"
import script from "./videoEditor.vue?vue&type=script&lang=js&"
export * from "./videoEditor.vue?vue&type=script&lang=js&"
import style0 from "./videoEditor.vue?vue&type=style&index=0&id=b466ebc2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b466ebc2",
  null
  
)

export default component.exports