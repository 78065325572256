<template>
    <div class="story-list-wrapper">
        <ul class="story-list">
            <listItem 
                v-for="(story, index) in stories" 
                :key="index" 
                :story="story"
            />
    </ul>
    </div>
</template>

<script>
import listItem from "@/components/list/listItem.vue";
export default {
    name: 'storyList',
    props: ['stories'],
    components: {
        listItem,
    },
    async mounted() {
        let amount = 120;
        let { storyList } = await this.$store.dispatch('getElements');
        storyList.addEventListener('wheel', (event) => {
            event.preventDefault();
            let direction = event.detail ? event.detail * -amount : event.wheelDelta;
            let position = storyList.scrollLeft;

            position += direction > 0 ? -amount : amount;
            storyList.scrollLeft = position;
        });
    }
}
</script>

<style lang="scss" scoped>
    .story-list-wrapper {
        display: flex;
    }
    .story-list{
        list-style: none;
        padding:0;
        margin:0;
        overflow-y: auto;
        white-space: nowrap;
        &::-webkit-scrollbar {
            height: 5px;
        }
        &::-webkit-scrollbar-track {
            background: #f1f1f1;
        }
        &::-webkit-scrollbar-thumb {
            background: #d92d77;
            border-radius: 16px;
        }
        &::-webkit-scrollbar-thumb:hover {
            background: #bd3381;
        }
    }
</style>